'use client'

import React from 'react'
import { HoneybadgerErrorBoundary } from '@honeybadger-io/react'
import { ThemeProvider } from 'next-themes'

import { ConversationProvider } from '@/app/context/ConversationContext'
import Honeybadger from '../honeybadger.browser.config'
import { Toaster } from './components/ui/toaster'

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <ThemeProvider attribute="data-theme" defaultTheme="system" enableSystem>
      <ConversationProvider>
        <HoneybadgerErrorBoundary honeybadger={Honeybadger}>
          {children}
          <div id="toaster">
            <Toaster />
          </div>
        </HoneybadgerErrorBoundary>
      </ConversationProvider>
    </ThemeProvider>
  )
}
